import { CountryCode, SelfClubhouse, SelfCountry } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  baseCountryCode: CountryCode | null;
  baseGmtOffset: number | null;
  clubhouses: SelfClubhouse[];
  countries: SelfCountry[];
  editMyAccountTabIndex: number;
}

const initialState: States = {
  baseCountryCode: null,
  baseGmtOffset: null,
  clubhouses: [],
  countries: [],
  editMyAccountTabIndex: 0,
};

export const myAccountSlice = createSlice({
  initialState,
  name: 'myAccountSlice',
  reducers: {
    setBaseCountryCode: (state, { payload }: PayloadAction<CountryCode>) => {
      state.baseCountryCode = payload;
    },
    setBaseGmtOffset: (state, { payload }: PayloadAction<number>) => {
      state.baseGmtOffset = payload;
    },
    setClubhouses: (state, { payload }: PayloadAction<SelfClubhouse[]>) => {
      state.clubhouses = payload;
    },
    setCountries: (state, { payload }: PayloadAction<SelfCountry[]>) => {
      state.countries = payload;
    },
    setEditMyAccountTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.editMyAccountTabIndex = payload;
    },
  },
});

export default myAccountSlice.reducer;

export const {
  setBaseCountryCode: setBaseCountryCodeAction,
  setBaseGmtOffset: setBaseGmtOffsetAction,
  setClubhouses: setClubhousesAction,
  setCountries: setCountriesAction,
  setEditMyAccountTabIndex: setEditMyAccountTabIndexAction,
} = myAccountSlice.actions;
