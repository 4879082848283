import { Root } from '_lib/Root';
import ReactDOM from 'react-dom/client';
import { Auth } from './Auth';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <>
    <Root>
      <Auth />
    </Root>
  </>,
);

reportWebVitals();
