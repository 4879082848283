import { Direction, OrderBy } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  listProductsEndCursor: string;
  listProductsOrderBy: OrderBy;
  listProductsPageNumber: number;
  listProductsPageSize: number;
  listProductsRowIndex: number;
  listProductsSearchTerm: string;
}

const initialState: States = {
  listProductsEndCursor: '',
  listProductsOrderBy: {
    direction: Direction.Asc,
    field: 'name',
  },
  listProductsPageNumber: 0,
  listProductsPageSize: 100,
  listProductsRowIndex: -1,
  listProductsSearchTerm: '',
};

export const productsListProductsSlice = createSlice({
  initialState,
  name: 'productsListProducts',
  reducers: {
    setListProductsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listProductsEndCursor = payload;
    },
    setListProductsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listProductsOrderBy = payload;
    },
    setListProductsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listProductsPageNumber = payload;
    },
    setListProductsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listProductsPageSize = payload;
    },
    setListProductsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listProductsRowIndex = payload;
    },
    setListProductsSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.listProductsSearchTerm = payload;
    },
  },
});

export default productsListProductsSlice.reducer;

export const {
  setListProductsEndCursor: setListProductsEndCursorAction,
  setListProductsPageNumber: setListProductsPageNumberAction,
  setListProductsPageSize: setListProductsPageSizeAction,
  setListProductsRowIndex: setListProductsRowIndexAction,
  setListProductsSearchTerm: setListProductsSearchTermAction,
} = productsListProductsSlice.actions;
