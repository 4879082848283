import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  isShowGrid: boolean;
}

const initialState: States = {
  isShowGrid: false,
};

export const supportSlice = createSlice({
  initialState,
  name: 'support',
  reducers: {
    setIsShowGrid: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowGrid = payload;
    },
  },
});

export default supportSlice.reducer;

export const { setIsShowGrid: setIsShowGridAction } = supportSlice.actions;
