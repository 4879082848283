import { UserTelemetryResourceType } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserTelemetry {
  pageName: string;
  resourceId?: string | undefined;
  resourceName?: string | undefined;
  resourceType?: UserTelemetryResourceType | null;
  ts: number;
}

export interface States {
  currentAppVersion: string;
  userTelemetry: UserTelemetry | null;
}

const initialState: States = {
  currentAppVersion: '',
  userTelemetry: null,
};

export const managementSystemSlice = createSlice({
  initialState,
  name: 'managementSystem',
  reducers: {
    setCurrentAppVersion: (state, { payload }: PayloadAction<string>) => {
      state.currentAppVersion = payload;
    },
    setUserTelemetry: (state, { payload }: PayloadAction<UserTelemetry>) => {
      state.userTelemetry = payload;
    },
  },
});

export const {
  setCurrentAppVersion: setCurrentAppVersionAction,
  setUserTelemetry: setUserTelemetryAction,
} = managementSystemSlice.actions;
