import { ClientState } from '_types';
import {
  AdminGameHistoryListItem,
  AdminScoreCard,
  ShopTokens,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ShopsTokens {
  [key: string]: ShopTokens;
}

type SetupShopsTokensInput = {
  currentShop: string;
  expires: string;
  shops: ShopsTokens;
};

type SetCurrentShopInput = {
  accessToken: string;
  clubhouseCode: string;
  clubhouseName: string;
  shopGuid: string;
  shopName: string;
};

type GameHistoryInput = {
  gameHistoryCurrentPage: number;
  gameHistoryHasScoreCard: boolean;
  gameHistoryList: AdminGameHistoryListItem[];
  gameHistoryMaxPage: number;
  gameHistoryTotalCount: number;
};

interface States {
  accessToken: string | null;
  clientDrawerContent: ClientState | null;
  clientStates: ClientState[];
  clubhouseName: null | string;
  currentShop: string;
  expires: number | null;
  gameHistoryCurrentPage: number;
  gameHistoryHasScoreCard: boolean;
  gameHistoryList: AdminGameHistoryListItem[];
  gameHistoryMaxPage: number;
  gameHistoryTotalCount: number;
  isShowControlDrawer: boolean;
  isShowScoreCardDrawer: boolean;
  notifications: boolean;
  refreshTimer: number;
  scoreCard: AdminScoreCard | null;
  settingsEditTabIndex: number;
  shopGuid: string | null;
  shopName: string | null;
  shopNames: string[];
  shopsTokens: ShopsTokens;
}

const initialState: States = {
  accessToken: null,
  clientDrawerContent: null,
  clientStates: [],
  clubhouseName: null,
  currentShop: '',
  expires: null,
  gameHistoryCurrentPage: 1,
  gameHistoryHasScoreCard: false,
  gameHistoryList: [],
  gameHistoryMaxPage: 0,
  gameHistoryTotalCount: 0,
  isShowControlDrawer: false,
  isShowScoreCardDrawer: false,
  notifications: false,
  refreshTimer: Date.now() + 1000 * 60 * 3,
  scoreCard: null,
  settingsEditTabIndex: 0,
  shopGuid: null,
  shopName: null,
  shopNames: [],
  shopsTokens: {},
};

export const shopSlice = createSlice({
  initialState,
  name: 'shop',
  reducers: {
    setClientDrawerContent: (
      state,
      { payload }: PayloadAction<ClientState>,
    ) => {
      state.clientDrawerContent = payload;
    },
    setClientStates: (state, { payload }: PayloadAction<ClientState[]>) => {
      state.clientStates = payload;
    },
    setCurrentShop: (
      state,
      { payload }: PayloadAction<SetCurrentShopInput>,
    ) => {
      state.currentShop = payload.clubhouseCode;
      state.accessToken = payload.accessToken;
      state.clubhouseName = payload.clubhouseName;
      state.shopGuid = payload.shopGuid;
      state.shopName = payload.shopName;
    },
    setGameHistory: (state, { payload }: PayloadAction<GameHistoryInput>) => {
      state.gameHistoryHasScoreCard = payload.gameHistoryHasScoreCard;
      state.gameHistoryList = payload.gameHistoryList;
      state.gameHistoryMaxPage = payload.gameHistoryMaxPage;
      state.gameHistoryTotalCount = payload.gameHistoryTotalCount;
    },
    setGameHistoryHasScoreCard: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.gameHistoryHasScoreCard = payload;
    },
    setIsShowControlDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowControlDrawer = payload;
    },
    setIsShowScoreCardDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowScoreCardDrawer = payload;
    },
    setNotifications: (state, { payload }: PayloadAction<boolean>) => {
      state.notifications = payload;
    },
    setRefreshTimer: (state, { payload }: PayloadAction<number>) => {
      state.refreshTimer = payload;
    },
    setScoreCard: (state, { payload }: PayloadAction<AdminScoreCard>) => {
      state.scoreCard = payload;
    },
    setSettingsEditTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.settingsEditTabIndex = payload;
    },
    setShopNames: (state, { payload }: PayloadAction<string[]>) => {
      state.shopNames = payload;
    },
    setupShopsTokens: (
      state,
      { payload }: PayloadAction<SetupShopsTokensInput>,
    ) => {
      const { currentShop } = payload;

      let preferredShop = '';

      if (currentShop) {
        preferredShop = currentShop;
      }

      if (!currentShop) {
        preferredShop = Object.keys(payload.shops)[0];
      }

      state.currentShop = preferredShop;
      state.expires = parseInt(payload.expires, 10);
      state.shopsTokens = payload.shops;
      state.accessToken = payload.currentShop
        ? payload.shops[payload.currentShop].kgat
        : payload.shops[currentShop].kgat;
      state.clubhouseName = payload.currentShop
        ? payload.shops[payload.currentShop].clubhouseName
        : payload.shops[currentShop].clubhouseName;
      state.shopGuid = payload.currentShop
        ? payload.shops[payload.currentShop].shopGuid
        : payload.shops[currentShop].shopGuid;
      state.shopName = payload.currentShop
        ? payload.shops[payload.currentShop].shopName
        : payload.shops[currentShop].shopName;
    },
  },
});

export default shopSlice.reducer;

export const {
  setClientDrawerContent: setClientDrawerContentAction,
  setClientStates: setClientStatesAction,
  setCurrentShop: setCurrentShopAction,
  setGameHistory: setGameHistoryAction,
  setGameHistoryHasScoreCard: setGameHistoryHasScoreCardAction,
  setIsShowControlDrawer: setIsShowControlDrawerAction,
  setIsShowScoreCardDrawer: setIsShowScoreCardDrawerAction,
  setNotifications: setNotificationsAction,
  setRefreshTimer: setRefreshTimerAction,
  setScoreCard: setScoreCardAction,
  setSettingsEditTabIndex: setSettingsEditTabIndexAction,
  setShopNames: setShopNamesAction,
  setupShopsTokens: setupShopsTokensAction,
} = shopSlice.actions;
