import { Spinner as CUISpinner } from '@chakra-ui/react';
interface SpinnerProps {
  justify: 'screen' | 'container' | 'inline';
}

export const Spinner = (props: SpinnerProps) => {
  const { justify } = props;

  if (justify === 'screen') {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <CUISpinner size="lg" />
      </div>
    );
  }

  if (justify === 'container') {
    return (
      <div className="flex items-center justify-center w-full">
        <CUISpinner size="lg" />
      </div>
    );
  }

  return <CUISpinner />;
};
