import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './types';

interface States {
  prevEventId: string | null;
  tabIndex: number;

  users: User[];
}

const initialState: States = {
  prevEventId: null,
  tabIndex: 0,

  users: [],
};

export const developerUserTelemetrySlice = createSlice({
  initialState,
  name: 'developerUserTelemetry',
  reducers: {
    setPrevEventId: (state, { payload }: PayloadAction<string>) => {
      state.prevEventId = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
    setUsers: (state, { payload }: PayloadAction<User[]>) => {
      state.users = payload;
    },
  },
});

export default developerUserTelemetrySlice.reducer;

export const {
  setPrevEventId: setPrevEventIdAction,
  setTabIndex: setTabIndexAction,
  setUsers: setUsersAction,
} = developerUserTelemetrySlice.actions;
