import { Heading, HStack } from '@chakra-ui/react';

interface CardHeadingProps {
  number?: number;
  pb?: number;
  pt?: number;
  size?: 'md' | 'sm' | 'lg' | 'xl' | '2xl' | '4xl' | '3xl' | 'xs';
  title: string;
}

export const CardHeading = (props: CardHeadingProps) => {
  const { number, pb = 3, pt, size = 'md', title } = props;

  return (
    <HStack pb={pb} pl={3} pt={pt} spacing={4} w="100%">
      {number && (
        <div className="flex items-center justify-center font-bold text-white rounded-full w-7 h-7 bg-s-icon-neutral">
          {number}
        </div>
      )}
      <Heading size={size}>{title}</Heading>
    </HStack>
  );
};
