export const currencyFormat = {
  currency: 'SGD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'currency',
};

export const currencyFormatSGD = {
  currency: 'SGD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'currency',
};

export const currencyFormatUSD = {
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: 'currency',
};

export const dateFormat = {
  day: 'numeric',
  month: 'long',
  weekday: 'long',
  year: 'numeric',
};

export const email = {
  pattern: {
    message: 'Please provide a valid email address',
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
};
