import { DbServiceEnvType } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  serverEnv: DbServiceEnvType;
  tabIndex: number;
}

const initialState: States = {
  serverEnv: DbServiceEnvType.Na,
  tabIndex: 0,
};

export const developerManagementSystemSlice = createSlice({
  initialState,
  name: 'developerManagementSystem',
  reducers: {
    setServerEnv: (state, { payload }: PayloadAction<DbServiceEnvType>) => {
      state.serverEnv = payload;
    },
    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default developerManagementSystemSlice.reducer;

export const {
  setServerEnv: setServerEnvAction,
  setTabIndex: setTabIndexAction,
} = developerManagementSystemSlice.actions;
